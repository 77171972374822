import { config } from "../../config";

import ReactMarkdown from "react-markdown";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";

import dayjs from "dayjs";
import es from "dayjs/locale/es";
dayjs.locale(es);

export const ArticleDetail = (props) => {
  const { article } = props;

  return (
    <div className="ml-article-container">
      <div className="ml-article-brand">
        {article.section != null ? article.section.singular_name : ""}
      </div>
      <div className="ml-article-details">
        <div className="ml-article-title-container">
          <h1 className="ml-article-title">{article.title}</h1>
          <p className="ml-article-date">
            {dayjs(article.date).format("MMMM D, YYYY h:mm A").toUpperCase()}
          </p>
        </div>
        <div className="ml-divider"></div>
        <ReactMarkdown>{article.tagline}</ReactMarkdown>
        <div className="ml-divider"></div>
        {article.media != null && (
          <div className="ml-article-player-container">
            {article.media.extension === "audio" && (
              <div className="ml-article-player-audio">
                {article.locutor != null && article.locutor.avatar != null && (
                  <div
                    className="ml-article-player-avatar"
                    style={{
                      backgroundImage: `url(${config.API_URL}${article.locutor.avatar.url})`,
                    }}
                  ></div>
                )}
              </div>
            )}
            {article.media.extension === "audio" ||
            article.media.extension === "video" ? (
              <div className="ml-article-player">
                <div className={`ml-article-player-${article.media.extension}`}>
                  <Plyr
                    source={{
                      type: article.media.extension,
                      title: article.title,
                      sources: [
                        {
                          src: `${config.API_URL}${article.media.url}`,
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
        <ReactMarkdown>{article.content}</ReactMarkdown>
      </div>
    </div>
  );
};
