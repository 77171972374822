import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  getPostsByDate,
  // getFeaturedPostsByDate,
  setFecha,
} from "../store/posts/actions";
import { getAdsByDate } from "../store/ads/actions";

import { HomeHeader } from "../components/home/HomeHeader";
import { Section } from "../components/home/Section";
import { AdsCarousel } from "../components/ads/AdsCarousel";
import { Loading } from "../components/loading/Loading";

import { config } from "../config";
import { ThemeMode } from "../App";

export const Report = () => {
  const { fecha } = useParams();
  const { isDarkMode, showAboveThreshold } = useContext(ThemeMode);

  console.log("Fecha: ", fecha);

  const dispatch = useDispatch();

  useEffect(() => {
    const myFecha = new Date(fecha + "T13:00:00");

    dispatch(getPostsByDate(myFecha));
    //dispatch(getFeaturedPostsByDate(myFecha));
    dispatch(getAdsByDate(myFecha));
    dispatch(setFecha(myFecha));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { posts, featured } = useSelector((state) => state.postsReducer);
  const { ads, adsLoading } = useSelector((state) => state.adsReducer);

  console.log("ads: ", ads);
  console.log("posts: ", posts);
  console.log("featured: ", featured);

  return (
    <div className={`ml-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="ml-main-content">
        {!adsLoading && ads.top && <AdsCarousel ads={ads.top}></AdsCarousel>}
        <div>
          {featured.loading ? (
            <Loading />
          ) : (
            <>
              {featured.data.length > 0 ? (
                <HomeHeader articles={featured.data ?? []} />
              ) : (
                <div></div>
              )}
            </>
          )}
          {posts.loading ? (
            <Loading />
          ) : (
            <>
              {posts.data.length <= 0 ? (
                <div className="ml-empty-data">No hay publicaciones</div>
              ) : (
                posts.data.map((section, idx) => (
                  <Section
                    key={idx}
                    id={section.id}
                    name={section.plural_name}
                    articles={section.articles}
                  />
                ))
              )}
            </>
          )}
        </div>
        {!adsLoading && ads.bottom && (
          <AdsCarousel ads={ads.bottom}></AdsCarousel>
        )}
      </div>
      <div className={`ml-ads-container ${showAboveThreshold ? 'with-ad' : 'without-ad'} 
        ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        {!adsLoading && ads.right && (
          <div className="vertical-ads-container">
            {ads.right.map((ad, idx) => (
              <div key={idx} className="vertical-add">
                <a
                  href={ad.link != null ? ad.link : "#"}
                  target={ad.link != null ? "_blank" : ""}
                  rel="noreferrer"
                >
                  <img src={`${config.API_URL}${ad.media.url}`} alt="" />
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
