import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import SEO from "../components/Seo";

import { AdsCarousel } from "../components/ads/AdsCarousel";
import { ArticleDetail } from "../components/articles/ArticleDetail";
import { Article } from "../components/articles/Article";
import { Loading } from "../components/loading/Loading";

import { useSelector, useDispatch } from "react-redux";
import { getPostDetails, getFeaturedPosts } from "../store/posts/actions";
import { getAds } from "../store/ads/actions";
import { ThemeMode } from "../App";

export const Nota = () => {
  const { id } = useParams();
  const { isDarkMode, showAboveThreshold } = useContext(ThemeMode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPostDetails(id));
    dispatch(getFeaturedPosts());
    dispatch(getAds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { post, featured } = useSelector((state) => state.postsReducer);
  const { ads, adsLoading } = useSelector((state) => state.adsReducer);

  //const seccion = post.data ? post.data.section.singular_name : "Seccion";
  console.log(post.data);

  const seccion = post.data ? post.data.section?.singular_name : "Seccion";
  const titulo = post.data ? post.data.title : "Titulo";

  return (
    <div className={`ml-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <SEO
        title={"Marcelo Longobardi | " + titulo}
        description={titulo}
        name={"Marcelo Longobardi | " + seccion}
        type="article"
      />
      <div className="ml-main-content">
        {post.loading ? (
          <Loading />
        ) : (
          <ArticleDetail article={post.data}></ArticleDetail>
        )}
      </div>
      <div className={`ml-ads-container ${showAboveThreshold ? 'with-ad' : 'without-ad'} 
        ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        {!adsLoading && ads.right && (
          <AdsCarousel ads={ads.right}></AdsCarousel>
        )}
        {!featured.loading && featured.data.length > 0 && (
          <div>
            <div className="ml-article-details-brand">TE PUEDE INTERESAR</div>
            {featured.data.map((article, idx) => (
              <Article key={idx} article={article} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
