import {
  SET_POSTS,
  SET_FEATURED_POSTS,
  SET_POST_DETAILS,
  SET_POSTS_BY_SECTION,
  SET_FECHA,
} from "./actionTypes";

const initialState = {
  posts: {
    data: [],
    loading: false,
  },
  featured: {
    data: [],
    loading: false,
  },
  section: {
    data: null,
    loading: false,
  },
  post: {
    data: {},
    loading: false,
  },
  fecha: null,
};

export function postsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_POSTS: {
      return {
        ...state,
        posts: action.payload.posts,
      };
    }
    case SET_FEATURED_POSTS: {
      return {
        ...state,
        featured: action.payload.featured,
      };
    }
    case SET_POSTS_BY_SECTION: {
      return {
        ...state,
        section: action.payload.section,
      };
    }
    case SET_POST_DETAILS: {
      return {
        ...state,
        post: action.payload.post,
      };
    }
    case SET_FECHA: {
      return {
        ...state,
        fecha: action.payload.fecha,
      };
    }
    default:
      return state;
  }
}
