import axios from "axios";
import {
  SET_POSTS,
  SET_FEATURED_POSTS,
  SET_POST_DETAILS,
  SET_POSTS_BY_SECTION,
  SET_FECHA,
} from "./actionTypes";
import { config } from "../../config";

export const setFecha = (fecha) => {
  return { type: SET_FECHA, payload: { fecha } };
};

// GET POSTS
export const getPosts = () => async (dispatch) => {
  dispatch(
    setPosts({
      data: [],
      loading: true,
    })
  );

  axios
    .get(`${config.API_URL}/api/posts`)
    .then(async (response) => {
      dispatch(
        setPosts({
          data: response.data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setPosts({
          data: [],
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};

const procesarPosts = (fecha, posts) => {
  const myPosts = JSON.parse(JSON.stringify(posts));

  Object.keys(myPosts).map((key) => {
    console.log("Seccion: ", posts[key].singular_name);

    const newArticles = [];
    myPosts[key].articles.map((article) => {
      const myFecha = new Date(article.publishedAt);

      if (myFecha < fecha) {
        console.log("Fecha:", article.publishedAt, "Art:", article.title);
        newArticles.push(article);
      }
    });
    myPosts[key].articles = newArticles;
  });
  return myPosts;
};

export const getPostsByDate = (fecha) => async (dispatch) => {
  dispatch(
    setPosts({
      data: [],
      loading: true,
    })
  );

  axios
    .get(`${config.API_URL}/api/posts`)
    .then(async (response) => {
      const myPosts = procesarPosts(fecha, response.data);

      dispatch(
        setPosts({
          data: myPosts,
          loading: false,
        })
      );

      const myFeatured = [];
      myFeatured.push(myPosts[0].articles[myPosts[0].articles.length - 1]);
      myFeatured.push(myPosts[1].articles[myPosts[1].articles.length - 1]);
      myFeatured.push(myPosts[2].articles[myPosts[2].articles.length - 1]);
      myFeatured.push(myPosts[3].articles[myPosts[3].articles.length - 1]);
      myFeatured.push(myPosts[4].articles[myPosts[4].articles.length - 1]);
      myFeatured.push(myPosts[3].articles[myPosts[3].articles.length - 2]);
      myFeatured.push(myPosts[4].articles[myPosts[4].articles.length - 2]);
      //myFeatured.push(myPosts[5].articles[myPosts[5].articles.length - 1]);
      //myFeatured.push(myPosts[5].articles[0]);
      //myFeatured.push(myPosts[6].articles[0]);
      console.log("New Featured ", myFeatured);

      dispatch(
        setFeaturedPosts({
          data: myFeatured,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setPosts({
          data: [],
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};

export const setPosts = (posts) => {
  return { type: SET_POSTS, payload: { posts } };
};

// GET FEATURED POSTS
export const getFeaturedPosts = () => async (dispatch) => {
  dispatch(
    setFeaturedPosts({
      data: [],
      loading: true,
    })
  );

  axios
    .get(`${config.API_URL}/api/posts/featured`)
    .then(async (response) => {
      dispatch(
        setFeaturedPosts({
          data: response.data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setFeaturedPosts({
          data: [],
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};

export const setFeaturedPosts = (featured) => {
  return { type: SET_FEATURED_POSTS, payload: { featured } };
};

// GET POST BY SECTION
export const getPostsBySection = (id) => async (dispatch) => {
  dispatch(
    setPostsBySection({
      data: null,
      loading: true,
    })
  );
  axios
    .get(`${config.API_URL}/api/posts/section/${id}`)
    .then(async (response) => {
      dispatch(
        setPostsBySection({
          data: response.data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setPostsBySection({
          data: null,
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};

export const setPostsBySection = (section) => {
  return { type: SET_POSTS_BY_SECTION, payload: { section } };
};

// GET POST DETAILS
export const getPostDetails = (id) => async (dispatch) => {
  dispatch(
    setPostDetails({
      data: null,
      loading: true,
    })
  );

  axios
    .get(`${config.API_URL}/api/posts/${id}`)
    .then(async (response) => {
      dispatch(
        setPostDetails({
          data: response.data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setPostDetails({
          data: null,
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};

export const setPostDetails = (post) => {
  return { type: SET_POST_DETAILS, payload: { post } };
};

export const getFeaturedPostsByDate = (fecha) => async (dispatch) => {
  dispatch(
    setFeaturedPosts({
      data: [],
      loading: true,
    })
  );

  axios
    .get(`${config.API_URL}/api/posts/featured`)
    .then(async (response) => {
      dispatch(
        setFeaturedPosts({
          data: response.data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setFeaturedPosts({
          data: [],
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};
