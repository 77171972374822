import { HashLink as Link } from "react-router-hash-link";
import { Article } from "../articles/Article";
import { Section2 } from "./Section2";
import { useBreakpoints } from "../../utils";
import { useContext } from "react";
import { ThemeMode } from "../../App";

export const Section = (props) => {
  const { id, name, articles, adsRight, count } = props;
  const { isDarkMode } = useContext(ThemeMode);
  const smDevice = useBreakpoints().smDevice;
  const array = smDevice ? null : [0, 1, 2, 3]

  return (
    <div className="ml-section">
      <div className={`ml-section-name ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <div>{name}</div>
        <Link smooth to={"/seccion/" + id} className={`ml-view-more-btn ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
          ver más
        </Link>
      </div>
      {
        /* name !== "Resúmen" && */ (
          <div className="ml-articles-grid">
            {
              array && array.map((x, i) => (
                <>
                  <div className={`card-wrapper ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                    < Article key={i} article={articles && articles[x]} />
                  </div>
                  {
                    !smDevice && adsRight && adsRight.length > 0 &&  (
                      <div className={`card-wrapper ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                        <Section2
                          key={i}
                          mediaUrl={adsRight[x].media.url}  // adsRight[count * 2 + x].media.url
                          link={adsRight[x].link}   // adsRight[count * 2 + x].link
                        />
                      </div>
                    )
                  }
                </>
              ))
            }
          </div>
        ) /* : (
          <div className="ml-articles-grid">
            {
              // array && array.map((x, i) => (
              <>
                {
                  !smDevice && adsRight && (
                    <Section2
                      mediaUrl={adsRight[count * 2].media.url}
                      link={adsRight[count * 2].link}
                    />
                  )
                }
                < Article article={articles && articles[2]} />
                < Article article={articles && articles[3]} />
                {
                  !smDevice && adsRight && (
                    <Section2
                      mediaUrl={adsRight[count * 2 + 1].media.url}
                      link={adsRight[count * 2 + 1].link}
                    />
                  )
                }
              </>
              // ))
            }
          </div>
        ) */
      }
      <br />
      <br />
    </div>
  );
};
