import { NavbarLayout } from "./layouts/NavbarLayout";
import { FooterLayout } from "./layouts/FooterLayout";
import { AppRouter } from "./routers/AppRouter";
import { HelmetProvider } from "react-helmet-async";
import "./sass/index.scss";
import { createContext, useEffect, useState } from "react";
import { useBreakpoints } from "./utils";
export const ThemeMode = createContext({});

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [showAboveThreshold, setShowAboveThreshold] = useState(true);
  const smDevice = useBreakpoints().smDevice;
  const THRESHOLD_BUFFER = 50;

  const handleScroll = () => {
    const threshold = smDevice ? (0.3 * document.documentElement.scrollHeight) : (0.4 * document.documentElement.scrollHeight);
    const currentScrollPosition = window.scrollY + window.innerHeight;

    if (currentScrollPosition > threshold + THRESHOLD_BUFFER && showAboveThreshold) {
      setShowAboveThreshold(false);
    } else if (currentScrollPosition < threshold - THRESHOLD_BUFFER && !showAboveThreshold) {
      setShowAboveThreshold(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [showAboveThreshold]);

  const toggleTheme = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <>
      <HelmetProvider>
        <ThemeMode.Provider value={{
          toggleTheme,
          isDarkMode,
          showAboveThreshold
        }}>
          <NavbarLayout />
          <AppRouter />
          <FooterLayout />
        </ThemeMode.Provider>
      </HelmetProvider>
    </>
  );
}

export default App;
