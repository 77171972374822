import React, { useContext } from "react";
import { ThemeMode } from "../../App";

const SvgLogoNavbar = () => {
	const { isDarkMode } = useContext(ThemeMode);

	return (
		<div className="nav-logo-container">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					viewBox="0 0 210 60" style={{ enableBackground: "new 0 0 210 60" }} xmlSpace="preserve">
					<g>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M22.3,39.6h6.5v4.6H16.9V23.4h5.4V39.6z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M40.4,22.7c3,0,5.7,1,8,3s3.5,4.6,3.5,7.7c0,3.4-1.1,6.1-3.2,8.3c-2.2,2.1-4.9,3.2-8.3,3.2
		c-3.3,0-6.1-1.1-8.3-3.2c-2.2-2.1-3.3-4.9-3.3-8.3c0-3.1,1.2-5.7,3.5-7.7C34.7,23.7,37.4,22.7,40.4,22.7L40.4,22.7z M36.2,29.6
		c-1.2,1.1-1.7,2.4-1.7,3.8c0,1.8,0.6,3.3,1.7,4.4c1.2,1.2,2.5,1.8,4.2,1.8c1.6,0,3-0.6,4.2-1.8s1.7-2.7,1.7-4.5
		c0-1.4-0.6-2.7-1.7-3.8c-1.2-1.1-2.5-1.7-4.2-1.7C38.7,28,37.3,28.5,36.2,29.6z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M53.7,23.4h5.4L69,36.1h0.1V23.4h5.4v20.8h-5.4l-9.9-12.8h-0.1v12.8h-5.4C53.7,44.2,53.7,23.4,53.7,23.4z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M98.1,32.6c0,1.5-0.1,3-0.4,4.3c-0.2,1.3-0.8,2.7-1.7,4c-1,1.4-2.3,2.4-3.7,3.1c-1.5,0.6-3.1,1-4.8,1
		c-3.3,0-5.9-1-8-3s-3.1-4.7-3.1-7.9c0-3.4,1-6.1,3.1-8.1s4.8-3.1,8.2-3.1c2.1,0,4,0.5,5.6,1.5s2.9,2.4,3.8,4.4L92,30.9
		c-0.4-1-0.9-1.8-1.7-2.4c-0.8-0.6-1.7-0.9-2.8-0.9c-1.7,0-3,0.6-3.9,1.9s-1.4,2.6-1.5,4.1c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3
		c0,0,0,0,0,0.1s0,0.1,0,0.1c0,1.6,0.5,3,1.5,4.4c0.9,1.3,2.3,2,4,2c1.2,0,2.2-0.3,3-0.9c0.8-0.6,1.3-1.5,1.4-2.7h-4.4v-4.3h10.5
		V32.6z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M110.9,22.7c3,0,5.7,1,8,3s3.5,4.6,3.5,7.7c0,3.4-1.1,6.1-3.2,8.3c-2.2,2.1-4.9,3.2-8.3,3.2
		c-3.3,0-6.1-1.1-8.3-3.2c-2.2-2.1-3.3-4.9-3.3-8.3c0-3.1,1.2-5.7,3.5-7.7C105.3,23.7,107.9,22.7,110.9,22.7L110.9,22.7z
		 M106.8,29.6c-1.2,1.1-1.7,2.4-1.7,3.8c0,1.8,0.6,3.3,1.7,4.4c1.2,1.2,2.5,1.8,4.2,1.8c1.6,0,3-0.6,4.2-1.8s1.7-2.7,1.7-4.5
		c0-1.4-0.6-2.7-1.7-3.8c-1.2-1.1-2.5-1.7-4.2-1.7C109.3,28,107.9,28.5,106.8,29.6z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M124.3,44.2V23.4h7.7c1.9,0,3.4,0.4,4.5,1.2c1.1,0.8,1.7,2.2,1.7,4.2c0,0.9-0.2,1.8-0.5,2.5s-0.9,1.3-1.8,1.7
		l0,0c1.5,0.2,2.6,0.8,3.2,1.7c0.7,0.9,1,2.2,1,3.6c0,2.2-0.8,3.7-2.3,4.5c-1.6,0.8-3.3,1.3-5.2,1.3h-8.3V44.2z M129.7,31.6h0.6
		c0,0,0,0,0.1,0h0.1c0.7,0,1.3-0.1,1.9-0.4c0.6-0.2,0.9-0.8,0.9-1.6c0-0.9-0.3-1.4-0.8-1.7c-0.6-0.2-1.2-0.4-1.8-0.4
		c-0.1,0-0.2,0-0.3,0s-0.2,0-0.3,0h-0.4V31.6z M129.7,40.1h0.7c0,0,0,0,0.1,0s0.1,0,0.1,0c0.2,0,0.5,0,0.7,0s0.5,0,0.7,0
		c0.7-0.1,1.3-0.2,1.9-0.5c0.6-0.3,0.9-0.9,0.9-1.7c0-0.9-0.3-1.6-0.9-1.8c-0.6-0.3-1.3-0.4-2-0.4c-0.2,0-0.3,0-0.5,0s-0.3,0-0.5,0
		c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0h-0.6v4.4H129.7z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M147.3,40.6l-1.4,3.6h-5.7l8-20.8h5.9l7.8,20.8h-5.8l-1.4-3.6H147.3z M151.1,29.8L151.1,29.8l-2.3,6.6h4.5
		L151.1,29.8z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M174.2,35.7l6.5,8.5H174l-5.1-8h-0.1v8h-5.4V23.4h8.1c2.1,0,3.8,0.5,5.2,1.6c1.4,1,2.1,2.6,2.1,4.9
		c0,1.4-0.4,2.7-1.2,3.8C176.8,34.8,175.7,35.4,174.2,35.7L174.2,35.7z M168.9,32.7h0.5c0.1,0,0.2,0,0.3,0s0.2,0,0.2,0
		c0.8,0,1.6-0.2,2.2-0.6c0.7-0.3,1-1,1-2s-0.3-1.7-1-2.1c-0.7-0.3-1.4-0.5-2.2-0.6c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0h-0.5V32.7
		z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M182.1,23.4h7.7c2.9,0,5.4,1,7.5,3s3.1,4.5,3.1,7.4s-1,5.4-3.1,7.4s-4.6,3-7.5,3h-7.7V23.4z M187.5,39.6h1.2
		c1.6,0,3-0.4,4.2-1.4c1.2-0.9,1.8-2.4,1.8-4.4c0-1.9-0.6-3.3-1.7-4.3c-1.1-1-2.5-1.5-4.3-1.5h-1.3v11.6H187.5z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M207.6,44.2h-5.4V23.4h5.4V44.2z" />
						<g className="st1">
							<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M35,20.8V4.7l-7.2,12.5h-1.1L19.5,4.7v16.1h-1.9V1.2h2l7.7,13.4l7.7-13.4h2v19.6H35z" />
							<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M47.9,1.2h1.6l8.1,19.6h-2l-2.5-6.1h-8.8l-2.5,6.1h-2.1L47.9,1.2z M52.6,13.1l-3.9-9.7l-4,9.7H52.6z" />
							<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M60.5,20.8V1.2h8.3c0.8,0,1.6,0.2,2.3,0.5c0.7,0.4,1.3,0.8,1.8,1.4c0.5,0.6,0.9,1.2,1.2,2
			c0.3,0.7,0.4,1.5,0.4,2.2c0,0.7-0.1,1.4-0.3,2.1c-0.2,0.7-0.5,1.2-0.9,1.8c-0.4,0.5-0.8,1-1.4,1.3c-0.5,0.4-1.1,0.6-1.8,0.7
			l4.8,7.6h-2.2l-4.6-7.2h-5.8v7.2H60.5z M62.4,11.8h6.4c0.6,0,1.1-0.1,1.6-0.4c0.5-0.2,0.9-0.6,1.2-1s0.6-0.9,0.8-1.4
			c0.2-0.5,0.3-1.1,0.3-1.7c0-0.6-0.1-1.2-0.3-1.7c-0.2-0.5-0.5-1-0.9-1.4c-0.4-0.4-0.8-0.7-1.3-1c-0.5-0.2-1-0.4-1.5-0.4h-6.2V11.8
			z"/>
							<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M77.2,10.8c0-1.2,0.2-2.4,0.6-3.5c0.4-1.2,1-2.2,1.8-3.1c0.8-0.9,1.8-1.7,2.9-2.3s2.5-0.9,3.9-0.9
			c1.7,0,3.2,0.4,4.5,1.2c1.2,0.8,2.2,1.8,2.7,3.1l-1.5,0.9c-0.3-0.6-0.7-1.2-1.1-1.6c-0.5-0.4-0.9-0.8-1.5-1.1
			c-0.5-0.3-1-0.5-1.6-0.6c-0.6-0.1-1.1-0.2-1.6-0.2c-1.2,0-2.2,0.2-3.1,0.7S81.6,4.6,81,5.4C80.4,6.2,79.9,7,79.6,8
			c-0.3,1-0.5,1.9-0.5,2.9c0,1.1,0.2,2.1,0.6,3.1s0.9,1.9,1.5,2.6c0.7,0.8,1.4,1.4,2.3,1.8s1.9,0.7,2.9,0.7c0.6,0,1.1-0.1,1.7-0.2
			c0.6-0.1,1.1-0.4,1.7-0.7c0.5-0.3,1-0.7,1.5-1.1c0.5-0.5,0.8-1,1.2-1.6l1.6,0.8c-0.3,0.7-0.8,1.4-1.3,1.9c-0.6,0.6-1.2,1-1.9,1.4
			c-0.7,0.4-1.4,0.7-2.2,0.9c-0.8,0.2-1.6,0.3-2.3,0.3c-1.3,0-2.6-0.3-3.7-0.9c-1.1-0.6-2.1-1.4-2.9-2.3c-0.8-0.9-1.4-2-1.9-3.2
			C77.4,13.3,77.2,12.1,77.2,10.8z"/>
							<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M110.6,19.1v1.7H97.5V1.2h12.9v1.7h-11V10h9.6v1.6h-9.6v7.5H110.6z" />
							<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M114.3,20.8V1.2h1.9v17.9h11.3v1.7H114.3z" />
							<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M137,20.9c-1.4,0-2.6-0.3-3.7-0.8c-1.1-0.6-2.1-1.3-2.9-2.2c-0.8-0.9-1.4-2-1.9-3.2c-0.4-1.2-0.7-2.4-0.7-3.7
			c0-1.3,0.2-2.6,0.7-3.8s1.1-2.3,1.9-3.2c0.8-0.9,1.8-1.7,2.9-2.2s2.3-0.8,3.6-0.8c1.4,0,2.6,0.3,3.7,0.9s2.1,1.3,2.9,2.3
			c0.8,0.9,1.4,2,1.9,3.2c0.4,1.2,0.7,2.4,0.7,3.6c0,1.3-0.2,2.6-0.7,3.8c-0.5,1.2-1.1,2.3-1.9,3.2c-0.8,0.9-1.8,1.6-2.9,2.2
			C139.6,20.6,138.4,20.9,137,20.9z M129.8,11c0,1.1,0.2,2.1,0.5,3.1c0.3,1,0.8,1.9,1.5,2.6c0.6,0.8,1.4,1.4,2.3,1.8s1.9,0.7,3,0.7
			c1.1,0,2.1-0.2,3-0.7c0.9-0.5,1.7-1.1,2.3-1.9c0.6-0.8,1.1-1.6,1.5-2.6c0.3-1,0.5-2,0.5-3c0-1.1-0.2-2.1-0.5-3.1
			c-0.4-1-0.9-1.9-1.5-2.6s-1.4-1.4-2.3-1.8c-0.9-0.5-1.9-0.7-2.9-0.7c-1.1,0-2.1,0.2-3,0.7s-1.7,1.1-2.3,1.9S130.6,7,130.3,8
			S129.8,10,129.8,11z"/>
						</g>
						<rect x="1" y="1.4" className="st2" width="7.9" height="43.3" />
					</g>
					<g id="gGhRBG_1_" className="st3">

						<image style={{ display: "inline", overflow: "visible", enableBackground: "new  " }} width="210" height="60" id="gGhRBG" xlinkHref="85E8AE7C0909A94C.jpg" />
					</g>
					<g className="st1">
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M1.3,53.9c0-0.4,0.1-0.8,0.2-1.1c0.1-0.4,0.3-0.7,0.6-1c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.3,1.3-0.3
		c0.6,0,1.1,0.1,1.5,0.4c0.4,0.3,0.7,0.6,0.9,1l-0.6,0.4C6,52.3,5.9,52.1,5.7,52c-0.1-0.1-0.3-0.2-0.4-0.3c-0.2-0.1-0.3-0.1-0.5-0.2
		c-0.2,0-0.3-0.1-0.5-0.1c-0.4,0-0.7,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.6S2.4,52.7,2.3,53c-0.1,0.3-0.1,0.6-0.1,0.9
		c0,0.3,0.1,0.7,0.2,1c0.1,0.3,0.3,0.6,0.5,0.8c0.2,0.2,0.4,0.4,0.7,0.6s0.6,0.2,0.9,0.2c0.2,0,0.3,0,0.5-0.1c0.2,0,0.3-0.1,0.5-0.2
		c0.2-0.1,0.3-0.2,0.5-0.4c0.1-0.1,0.3-0.3,0.3-0.5l0.7,0.4c-0.1,0.2-0.2,0.5-0.4,0.6c-0.2,0.2-0.4,0.3-0.6,0.5S5.3,57,5.1,57
		c-0.3,0.1-0.5,0.1-0.8,0.1c-0.4,0-0.8-0.1-1.2-0.3c-0.4-0.2-0.7-0.4-0.9-0.8c-0.3-0.3-0.5-0.7-0.6-1.1C1.4,54.7,1.3,54.3,1.3,53.9z
		"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M8.9,57.1v-6.4h2.7c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.3,0.4,0.4,0.7
		c0.1,0.2,0.1,0.5,0.1,0.7c0,0.2,0,0.4-0.1,0.6s-0.2,0.4-0.3,0.6c-0.1,0.2-0.3,0.3-0.4,0.4s-0.3,0.2-0.6,0.3l1.5,2.4h-0.9l-1.5-2.3
		H9.7v2.3H8.9z M9.7,54.1h1.9c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.1-0.5
		c0-0.2,0-0.3-0.1-0.5s-0.1-0.3-0.3-0.4s-0.2-0.2-0.4-0.3s-0.3-0.1-0.4-0.1H9.7V54.1z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M20.1,56.4v0.7h-4.3v-6.4H20v0.7h-3.4v2.1h3v0.7h-3v2.2H20.1z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M22.2,57.1v-6.4h2.2c0.5,0,1,0.1,1.3,0.3c0.4,0.2,0.7,0.4,1,0.7s0.5,0.6,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2
		c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.4-0.4,0.7-0.6,1c-0.3,0.3-0.6,0.5-1,0.6s-0.8,0.2-1.3,0.2H22.2z M26.7,53.9c0-0.4,0-0.7-0.1-1
		c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.5s-0.6-0.2-1-0.2H23v5h1.4c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5
		c0.2-0.2,0.3-0.5,0.4-0.8C26.6,54.6,26.7,54.2,26.7,53.9z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M29.7,57.1v-6.4h0.8v6.4H29.7z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M37.9,55.4c0,0.2,0,0.5-0.1,0.7s-0.2,0.4-0.4,0.5c-0.2,0.1-0.4,0.3-0.6,0.3s-0.5,0.1-0.7,0.1h-3v-6.4h3.2
		c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.2,0.5,0.4c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6c0,0.3-0.1,0.6-0.2,0.9
		c-0.2,0.3-0.4,0.5-0.7,0.6c0.4,0.1,0.7,0.3,0.9,0.6S37.9,55.1,37.9,55.4z M33.8,51.4v2.1h2c0.1,0,0.3,0,0.4-0.1
		c0.1-0.1,0.2-0.1,0.3-0.2s0.2-0.2,0.2-0.3c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3s-0.2-0.2-0.3-0.2
		c-0.1-0.1-0.2-0.1-0.4-0.1H33.8z M37.1,55.3c0-0.1,0-0.3-0.1-0.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.2
		c-0.1-0.1-0.3-0.1-0.4-0.1h-2.3v2.2H36c0.1,0,0.3,0,0.4-0.1s0.2-0.1,0.3-0.2c0.1-0.1,0.2-0.2,0.2-0.3S37.1,55.4,37.1,55.3z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M40,57.1v-6.4h0.8v6.4H40z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M43.4,57.1v-6.4h0.8v5.7h3.6v0.7H43.4z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M49.6,57.1v-6.4h0.8v6.4H49.6z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M53,57.1v-6.4h2.2c0.5,0,1,0.1,1.3,0.3c0.4,0.2,0.7,0.4,1,0.7s0.5,0.6,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2
		c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.4-0.4,0.7-0.6,1c-0.3,0.3-0.6,0.5-1,0.6s-0.8,0.2-1.3,0.2H53z M57.4,53.9c0-0.4,0-0.7-0.1-1
		c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.5s-0.6-0.2-1-0.2h-1.4v5h1.4c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5
		c0.2-0.2,0.3-0.5,0.4-0.8C57.4,54.6,57.4,54.2,57.4,53.9z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M62.1,50.7h0.7l2.6,6.4h-0.9l-0.8-1.9h-2.7l-0.7,1.9h-0.9L62.1,50.7z M63.6,54.6l-1.2-3l-1.2,3H63.6z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M67.3,57.1v-6.4h2.2c0.5,0,1,0.1,1.3,0.3c0.4,0.2,0.7,0.4,1,0.7s0.5,0.6,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2
		c0,0.5-0.1,0.9-0.2,1.3c-0.1,0.4-0.4,0.7-0.6,1c-0.3,0.3-0.6,0.5-1,0.6s-0.8,0.2-1.3,0.2H67.3z M71.8,53.9c0-0.4,0-0.7-0.1-1
		c-0.1-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.5s-0.6-0.2-1-0.2h-1.4v5h1.4c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.5-0.3,0.7-0.5
		c0.2-0.2,0.3-0.5,0.4-0.8C71.7,54.6,71.8,54.2,71.8,53.9z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M74.5,57.8l0.2-0.8h-0.3v-0.9h0.7V57l-0.3,0.8H74.5z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M83.4,57.1c-0.4,0-0.9-0.1-1.2-0.3s-0.7-0.4-0.9-0.7c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.2-1.2
		c0-0.4,0.1-0.8,0.2-1.2s0.4-0.7,0.6-1s0.6-0.5,1-0.7c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,0.9,0.7
		c0.3,0.3,0.5,0.7,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.6,1c-0.3,0.3-0.6,0.5-1,0.7
		C84.2,57.1,83.8,57.1,83.4,57.1z M81.2,53.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.6,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.6
		c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6s0.3-0.5,0.4-0.8c0.1-0.3,0.1-0.6,0.1-0.9
		c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2c-0.3,0-0.6,0.1-0.9,0.2
		c-0.3,0.1-0.5,0.3-0.7,0.6c-0.2,0.2-0.3,0.5-0.4,0.8C81.2,53.3,81.2,53.6,81.2,53.9z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M88.5,57.1v-6.4h2.7c0.3,0,0.5,0.1,0.8,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.2,0.2,0.3,0.4,0.4,0.7
		c0.1,0.2,0.1,0.5,0.1,0.7c0,0.3,0,0.5-0.1,0.8s-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.3-0.6,0.5s-0.5,0.2-0.8,0.2h-1.9v2.3H88.5z
		 M89.3,54.1h1.9c0.2,0,0.3,0,0.5-0.1c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.1,0.2-0.3,0.2-0.4c0.1-0.2,0.1-0.3,0.1-0.5
		c0-0.2,0-0.4-0.1-0.5s-0.1-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.4-0.1h-1.8V54.1z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M95.1,57.1v-6.4h0.8v6.4H95.1z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M99.3,52.2v4.9h-0.8v-6.4h0.6l4,5v-5h0.8v6.4h-0.7L99.3,52.2z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M106.4,57.1v-6.4h0.8v6.4H106.4z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M112.3,57.1c-0.5,0-0.9-0.1-1.2-0.3c-0.4-0.2-0.7-0.4-1-0.7c-0.3-0.3-0.5-0.6-0.6-1c-0.1-0.4-0.2-0.8-0.2-1.2
		c0-0.4,0.1-0.8,0.2-1.2c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.3,1.2-0.3c0.5,0,0.9,0.1,1.2,0.3
		c0.4,0.2,0.7,0.4,0.9,0.7c0.3,0.3,0.5,0.7,0.6,1c0.1,0.4,0.2,0.8,0.2,1.2c0,0.4-0.1,0.8-0.2,1.2s-0.4,0.7-0.6,1
		c-0.3,0.3-0.6,0.5-1,0.7C113.2,57.1,112.8,57.1,112.3,57.1z M110.1,53.9c0,0.3,0.1,0.6,0.2,0.9c0.1,0.3,0.3,0.6,0.4,0.8
		c0.2,0.2,0.4,0.4,0.7,0.6c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.5,0.4-0.8
		c0.1-0.3,0.1-0.6,0.1-0.9c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.3-0.6-0.5-0.8c-0.2-0.2-0.4-0.4-0.7-0.6c-0.3-0.1-0.6-0.2-0.9-0.2
		c-0.3,0-0.6,0.1-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.6c-0.2,0.2-0.3,0.5-0.4,0.8C110.2,53.3,110.1,53.6,110.1,53.9z M112.5,49.9
		l-0.5-0.2l0.5-0.9h0.8L112.5,49.9z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M118.3,52.2v4.9h-0.8v-6.4h0.6l4,5v-5h0.8v6.4h-0.7L118.3,52.2z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M128.6,50.7l1.9,3.3l2-3.3h0.9l-2.4,4.1v2.3h-0.8v-2.3l-2.4-4H128.6z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M139.8,50.7h0.7l2.6,6.4h-0.9l-0.8-1.9h-2.7l-0.7,1.9h-0.9L139.8,50.7z M141.3,54.6l-1.2-3l-1.2,3H141.3z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M145.8,52.2v4.9H145v-6.4h0.6l4,5v-5h0.8v6.4h-0.7L145.8,52.2z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M154.9,50.7h0.7l2.6,6.4h-0.9l-0.8-1.9h-2.7l-0.7,1.9h-0.9L154.9,50.7z M156.4,54.6l-1.2-3l-1.2,3H156.4z
		 M155.3,49.9l-0.5-0.2l0.5-0.9h0.8L155.3,49.9z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M160,57.1v-6.4h0.8v5.7h3.6v0.7H160z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M166.3,57.1v-6.4h0.8v6.4H166.3z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M173.4,52c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
		c-0.2,0-0.4-0.1-0.6-0.1c-0.5,0-0.9,0.1-1.1,0.3c-0.2,0.2-0.3,0.4-0.3,0.8c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3
		c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.3,0.4,0.5
		c0.1,0.2,0.1,0.4,0.1,0.7c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.3-0.7,0.3c-0.3,0.1-0.6,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.4-0.2c-0.4-0.2-0.8-0.4-1.2-0.7l0.4-0.7c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.2
		c0.2,0.1,0.5,0.1,0.7,0.1c0.5,0,0.8-0.1,1.1-0.2s0.4-0.4,0.4-0.7c0-0.2,0-0.3-0.1-0.5s-0.2-0.2-0.4-0.3s-0.3-0.2-0.6-0.2
		c-0.2-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.6-0.2-0.9-0.3s-0.5-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.3,0.7-0.4s0.6-0.1,0.9-0.1c0.4,0,0.8,0.1,1.2,0.2
		c0.3,0.1,0.7,0.3,0.9,0.5L173.4,52z"/>
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M176.1,57.1v-6.4h0.8v6.4H176.1z" />
						<path className={`st0 ${isDarkMode ? 'dark-mode' : 'light-mode'}`} d="M183.2,52c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.1-0.3-0.1-0.4-0.2c-0.2-0.1-0.3-0.1-0.5-0.2
		c-0.2,0-0.4-0.1-0.6-0.1c-0.5,0-0.9,0.1-1.1,0.3c-0.2,0.2-0.3,0.4-0.3,0.8c0,0.2,0,0.3,0.1,0.4c0.1,0.1,0.2,0.2,0.3,0.3
		c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.5,0.1,0.7,0.2c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0.1,0.5,0.2,0.7,0.3c0.2,0.1,0.3,0.3,0.4,0.5
		c0.1,0.2,0.1,0.4,0.1,0.7c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.5,0.3-0.7,0.3c-0.3,0.1-0.6,0.1-0.9,0.1
		c-0.5,0-1-0.1-1.4-0.2c-0.4-0.2-0.8-0.4-1.2-0.7l0.4-0.7c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.6,0.2
		c0.2,0.1,0.5,0.1,0.7,0.1c0.5,0,0.8-0.1,1.1-0.2s0.4-0.4,0.4-0.7c0-0.2,0-0.3-0.1-0.5s-0.2-0.2-0.4-0.3s-0.3-0.2-0.6-0.2
		c-0.2-0.1-0.5-0.1-0.8-0.2c-0.3-0.1-0.6-0.2-0.9-0.3s-0.5-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.6
		c0-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.3-0.4,0.5-0.6c0.2-0.2,0.4-0.3,0.7-0.4s0.6-0.1,0.9-0.1c0.4,0,0.8,0.1,1.2,0.2
		c0.3,0.1,0.7,0.3,0.9,0.5L183.2,52z"/>
					</g>
				</svg>
			</svg>

		</div>
	)
};

export default SvgLogoNavbar