import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPosts, getFeaturedPosts } from "../store/posts/actions";
import { getAds } from "../store/ads/actions";
import SEO from "../components/Seo";
import { HomeHeader } from "../components/home/HomeHeader";
import { Section } from "../components/home/Section";
import { AdsCarousel } from "../components/ads/AdsCarousel";
import { Loading } from "../components/loading/Loading";
// import SliderShow from "../components/custom/SliderShow";
import { useBreakpoints } from "../utils";
import { BottomAd } from "../components/ads/BottomAd";
import { ThemeMode } from "../App";
// import { Section2 } from "../components/home/Section2";
// import { config } from "../config";
import SectionMobileRightAds from "../components/home/SectionMobileRightAds";

export const Home = () => {
  const { isDarkMode, showAboveThreshold } = useContext(ThemeMode);
  const dispatch = useDispatch();
  const [adBottom, setAdBottom] = useState(true);

  useEffect(() => {
    dispatch(getPosts());
    dispatch(getFeaturedPosts());
    dispatch(getAds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { posts, featured } = useSelector((state) => state.postsReducer);
  const { ads, adsLoading } = useSelector((state) => state.adsReducer);

  // const images = ads?.right && ads?.right.map((v, i) => v.media.url);
  const smDevice = useBreakpoints().smDevice;

  return (
    <>
      <div
        className={`ml-container ${isDarkMode ? "dark-mode" : "light-mode"}`}
      >
        <SEO
          title="Marcelo Longobardi | Sitio Oficial"
          description="Sitio Oficial de Marcelo Longobardi"
          name="Marcelo Longobardi"
          type="website"
        />
        <div className="ml-main-content">
          {/* {
            smDevice && (
              <SliderShow images={images} />
            )
          } */}
          {!adsLoading && ads.top && <AdsCarousel ads={ads.top}></AdsCarousel>}
          <div>
            {featured.loading ? (
              <Loading />
            ) : (
              <>
                {featured.data.length > 0 ? (
                  <HomeHeader articles={featured.data ?? []} />
                ) : (
                  <div></div>
                )}
              </>
            )}
            {posts.loading ? (
              <Loading />
            ) : (
              <>
                {posts.data.length <= 0 ? (
                  <div className="ml-empty-data">No hay publicaciones</div>
                ) : (
                  posts.data.map((section, idx) => (
                    <Section
                      key={idx}
                      count={idx + 1}
                      id={section.id}
                      name={section.plural_name}
                      articles={section.articles}
                      adsRight={ads["x-standard"]}
                    />
                  ))
                )}
              </>
            )}
          </div>
          {!adsLoading && ads.bottom && (
            <AdsCarousel
              ads={ads.bottom}
              width="100%"
              height="100%"
            ></AdsCarousel>
          )}
        </div>
        <div
          className={`ml-ads-container ${
            showAboveThreshold ? "with-ad" : "without-ad"
          } 
        ${isDarkMode ? "dark-mode" : "light-mode"}`}
        >
          {!adsLoading && ads["x-right"] && (
            <div
              className={`ml-ads-card ${
                isDarkMode ? "dark-mode" : "light-mode"
              }`}
            >
              <AdsCarousel ads={ads["x-right"]} width="100%" height="100%" />
            </div>
          )}
        </div>
      </div>
      {smDevice && !adsLoading && ads["right"] && ads["x-right"] && (
        <SectionMobileRightAds
          isDarkMode={isDarkMode}
          adsXRight={ads["x-right"]}
          adsRight={ads["right"]}
        />
      )}
      {adBottom && (
        <div className="bottom-ad-container">
          <BottomAd
            smDevice={smDevice}
            xBottom={smDevice ? ads["x-bottom-mobile"] : ads["x-bottom"]}
            handleClose={() => {
              setAdBottom(false);
            }}
          />
        </div>
      )}
    </>
  );
};
