import cloudworks from "../assets/img/cloudworks.png";

export const FooterLayout = () => {
  return (
    <footer className="ml-footer">
      <div className="ml-footer-content">
        <div className="ml-footer-text">
          Argentina © 2010 / 2022 Marcelo Longobardi
        </div>
        <div className="ml-cloudworks-logo">
          <a
            href="https://cloudworks.global/"
            target="_blank"
            className="ml-cloudworks-btn"
            rel="noreferrer"
          >
            <img src={cloudworks} alt="" />
          </a>
        </div>
      </div>
    </footer>
  );
};
