import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import SEO from "../components/Seo";

import { AdsCarousel } from "../components/ads/AdsCarousel";
import { HomeHeader } from "../components/home/HomeHeader";
import { Loading } from "../components/loading/Loading";

import { useSelector, useDispatch } from "react-redux";
import { getPostsBySection } from "../store/posts/actions";
import { getAds } from "../store/ads/actions";

import { config } from "../config";
import { ThemeMode } from "../App";

export const Section = () => {
  const { id } = useParams();
  const { isDarkMode, showAboveThreshold } = useContext(ThemeMode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAds());
    dispatch(getPostsBySection(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const { section } = useSelector((state) => state.postsReducer);
  const { ads, adsLoading } = useSelector((state) => state.adsReducer);

  const seccion = section.data ? section.data.plural_name : "Seccion";

  return (
    <div className={`ml-container ml-section-page ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <SEO
        title={"Marcelo Longobardi | Seccion " + seccion}
        description={"Marcelo Longobardi | Seccion " + seccion}
        name={"Marcelo Longobardi | " + seccion}
        type="article"
      />
      <div className="ml-main-content">
        {!adsLoading && ads.top && <AdsCarousel ads={ads.top}></AdsCarousel>}
        <div>
          {!section.loading && section.data ? (
            <>
              {section.data.articles.length <= 0 ? (
                <div className="ml-empty-data">No hay publicaciones</div>
              ) : (
                <div>
                  <div className="ml-section">
                    <div className="ml-section-name">
                      <div>{section.data.plural_name}</div>
                    </div>
                    <br />
                  </div>
                  <HomeHeader articles={[...section.data.articles] ?? []} />
                </div>
              )}
            </>
          ) : (
            <Loading />
          )}
        </div>
        {!adsLoading && ads.bottom && (
          <AdsCarousel ads={ads.bottom}></AdsCarousel>
        )}
      </div>
      <div className={`ml-ads-container ${showAboveThreshold ? 'with-ad' : 'without-ad'} 
        ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        {!adsLoading && ads.right && (
          <div className="vertical-ads-container">
            {ads.right.map((ad, idx) => (
              <div key={idx} className="vertical-add">
                <a
                  href={ad.link != null ? ad.link : "#"}
                  target={ad.link != null ? "_blank" : ""}
                  rel="noreferrer"
                >
                  <img src={`${config.API_URL}${ad.media.url}`} alt="" />
                </a>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
