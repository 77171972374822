import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Home } from "../pages/Home";
import { Nota } from "../pages/Nota";
import { Section } from "../pages/Section";
import { Reports } from "../pages/Reports";
import { Report } from "../pages/Report";

export const AppRouter = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/nota/:id" element={<Nota />} />
      <Route path="/seccion/:id" element={<Section />} />
      <Route path="/reports" element={<Reports />}></Route>
      <Route path="/report/:fecha" element={<Report />} />
    </Routes>
  );
};
