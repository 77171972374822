import { HashLink as Link } from "react-router-hash-link";
import { config } from "../../config";

export const FirstArticle = (props) => {
  const { article } = props;
  //console.log("First Article:", article);

  const imagen = article.image
    ? article.image.url
    : "/uploads/Marcelo_Longobardi_NEUTRA_1f501e9366.jpg";

  return (
    <div>
      <div className="ml-horizontal-card">
        <div
          className="ml-horizontal-card-img"
          style={{
            backgroundImage: `url(${config.API_URL}${imagen})`,
          }}
        >
          <div className="ml-horizontal-card-brand brand-left">
            {article.section != null ? article.section.singular_name : ""}
          </div>
        </div>
        <div className="ml-horizontal-card-content">
          <Link
            smooth
            to={"/nota/" + article.id}
            className="ml-horizontal-card-link"
          >
            <span style={{ color: "#fff" }}>{article.title}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
