/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands, solid } from "@fortawesome/fontawesome-svg-core/import.macro"; */
import { HashLink as Link } from "react-router-hash-link";

import { useSelector } from "react-redux";

import SvgLogoNavbar from "../components/custom/SvgLogoNavbar";
import { useContext } from "react";
import { ThemeMode } from "../App";
import SvgInstagramIcon from "../components/custom/SvgInstagramIcon";
import SvgYoutubeIcon from "../components/custom/SvgYoutubeIcon";
import { useBreakpoints } from "../utils";
import { config } from "../config";

const Fecha = () => {
  const { fecha } = useSelector((state) => state.postsReducer);

  const fecha2 = fecha ? fecha : new Date();
  const dia = fecha2.getDate();
  const mes = fecha2.getMonth();
  const anio = fecha2.getFullYear();
  const diaSemana = fecha2.getDay();
  const dias = [
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ];
  const meses = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const textoFecha =
    dias[diaSemana] + " " + dia + " de " + meses[mes] + " de " + anio;

  return <div className="ml-fecha">{textoFecha}</div>;
};

// Facebook https://www.facebook.com/LongobardiOficial/

//Instagram https://www.instagram.com/longobardioficial/

//Twitter https://twitter.com/longobardim

export const NavbarLayout = () => {
  const { showAboveThreshold } = useContext(ThemeMode);
  const { ads, adsLoading } = useSelector((state) => state.adsReducer);
  // const imageBanner = "https://tpc.googlesyndication.com/simgad/2656136522964266659";
  const { isDarkMode, toggleTheme } = useContext(ThemeMode);
  const smDevice = useBreakpoints().smDevice;
  const xTop = !adsLoading && ads && (
    smDevice ? ads["x-top-mobile"] : ads["x-top"]
  );
  const imageBanner = xTop && xTop.length > 0 ? xTop[0].media.url : null;
  const socialLinks = [
    {
      icon: <SvgYoutubeIcon fillColor={isDarkMode ? '#FFF' : '#000'} height="20px" />,
      link: "#",
    },
    {
      icon: <SvgInstagramIcon fillColor={isDarkMode ? '#FFF' : '#000'} height="20px" />,
      link: "#",
    },
    /* {
      icon: brands("twitter"),
      link: "#",
    }, */
  ];

  return (
    <>
      {
        showAboveThreshold && (
          <div className={`top-banner-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            {
              !adsLoading && ads && (
                <img src={`${config.API_URL}${imageBanner}`} alt="" />
              )
            }
          </div>
        )
      }
      <div className={`ml-navbar-container ${isDarkMode ? 'dark-mode' : 'light-mode'}
       ${showAboveThreshold ? 'with-ad' : 'without-ad'} 
        ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <nav className="ml-navbar">
          <Link smooth to={"/"} className="ml-navbar-logo">
            <SvgLogoNavbar />
          </Link>

          <div className="ml-social-btn-container">
            <Fecha />
            {socialLinks.map((social, idx) => (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                key={idx}
                href={social.link}
                className={`ml-social-btn ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                rel="noreferrer"
              >
                {social.icon}
              </a>
            ))}
            <div class="display-theme-mode">
              <label class="label toggle">
                <input type="checkbox" class="toggle_input" onChange={() => toggleTheme()} />
                <div class="toggle-control"></div>
              </label>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
