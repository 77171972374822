import { useMediaQuery } from "usehooks-ts";

export const intercalateElementsFromArray = (arr1, arr2) => {
    return arr1.flatMap((value, index) => arr2[index] !== undefined ? [value, arr2[index]] : value);
}

export const useBreakpoints = () => {
    const isMobile = useMediaQuery("(min-width: 0px) and (max-width: 410px)");
    const smDevice = useMediaQuery("(min-width: 0px) and (max-width: 600px)");
    const tableMode = useMediaQuery("(min-width: 0px) and (max-width: 850px)");
    const customMode1 = useMediaQuery("(min-width: 0px) and (max-width: 1024px)");

    return {
        isMobile,
        smDevice,
        tableMode,
        customMode1,
    };
};