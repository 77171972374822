import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";

import { BrowserRouter } from "react-router-dom";

import ReactGA from "react-ga4";

import App from "./App";

const GA_MEASUREMENT_ID = "G-7JWHXM079J"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(GA_MEASUREMENT_ID);

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);
