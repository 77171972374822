import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import { config } from "../../config";

export const AdsCarousel = (props) => {
  const { ads, width, height } = props;

  return (
    <Carousel
      showArrows={false}
      showStatus={false}
      showIndicators={false}
      showThumbs={false}
      autoPlay={true}
      interval={12000}
      infiniteLoop={true}
    >
      {ads && Object.values(ads).map((ad, idx) => (
        <div key={idx}>
          <a
            href={ad.link != null ? ad.link : "#"}
            target={ad.link != null ? "_blank" : ""}
            rel="noreferrer"
          >
            <img src={`${config.API_URL}${ad.media.url}`} alt="" width={width} height={height} />
          </a>
        </div>
      ))}
    </Carousel>
  );
};
