// import { Section2 } from "../home/Section2";
import "../../sass/ml_styles/_ad.scss";
import cancelBtn from "../../assets/img/svg/cancel.svg";
import { config } from "../../config";

export const BottomAdDesktop = (props) => {
  const { xBottom, handleClose } = props;
  // const bottomBanner = "https://tpc.googlesyndication.com/simgad/3889285935231808346"

  const handleKeyEnter = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleClose(event);
    }
  };

  return (
    <div className="ml-bottomad-container">
      <div className="cancel-button">
        <button
          onKeyDown={(e) => handleKeyEnter(e)}
          onClick={(e) => {
            handleClose(e);
          }}
          style={{ borderColor: "white" }}
        >
          <img
            src={cancelBtn}
            alt="Cancel"
            style={{
              width: "16px",
              height: "16px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          />
        </button>
      </div>
      {xBottom && xBottom.length > 0 && (
        <div className="ml-bottom-ad-card">
          <img
            src={`${config.API_URL}${xBottom[0].media.url}`}
            alt=""
            width="100%"
            height="100%"
          />
        </div>
      )}
    </div>
  );
};
