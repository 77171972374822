import { config } from "../../config"


const SectionMobileRightAds = ({ adsXRight, adsRight, isDarkMode = false }) => {

    return (
        <div className={`mobile-container-right-ads ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
            {
                adsXRight && adsXRight.length > 0 && (
                    <div className={`card-wrapper2 ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                        <img src={`${config.API_URL}${adsXRight[0].media.url}`} alt="" width="300" height="450" />
                    </div>
                )
            }
            {
                Object.values(adsRight).map((ad, idx) => {
                    return (
                        <div key={idx} className={`card-wrapper3 ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
                            <img src={`${config.API_URL}${ad.media.url}`} alt="" width="250" height="300" />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default SectionMobileRightAds