// import { HashLink as Link } from "react-router-hash-link";
// import { Article } from "../articles/Article";
import { config } from "../../config";

export const Section2 = (props) => {
    const { mediaUrl, link } = props;

    return (
        <a
            href={link != null ? link : "#"}
            target={link != null ? "_blank" : ""}
            rel="noreferrer"
        >
            <img src={`${config.API_URL}${mediaUrl}`} alt="" width="100%" height="100%" />
        </a>
    );
};
