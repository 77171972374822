import React, { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../config/index";

export const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [jwt, setJwt] = useState(null);
  const [ads, setAds] = useState([]);
  const [reports, setReports] = useState([]);

  const [form, setForm] = useState({
    identifier: "",
    password: "",
    month: "enero",
  });

  const login = async () => {
    setLoading(true);

    const { identifier, password } = form;

    await axios
      .post(
        `${config.API_URL}/api/auth/local`,
        { identifier, password },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(async (res) => {
        setJwt(res.data.jwt);
      })
      .catch((err) => {
        console.log(err);
        alert("Error al iniciar sesión");
      })
      .finally(() => setLoading(false));
  };

  const getAds = async () => {
    setLoading(true);

    const { month } = form;

    axios
      .get(`${config.API_URL}/api/adsforreport/${month}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then(async (res) => {
        setAds(res.data);
      })
      .catch((err) => {
        console.log(err);
        alert("Error al obtener los anuncios");
      })
      .finally(() => setLoading(false));
  };

  const generateReport = async () => {
    setLoading(true);

    for (let i = 0; i < ads.length; i++) {
      const add = ads[i];

      if (!add.pdf) {
        let res = await axios.post(
          `${config.API_URL}/api/adsreportgenerate`,
          add,
          {
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        setReports((prev) => [...prev, { ...add, pdf: res.data.pdf }]);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (jwt != null) {
      getAds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  useEffect(() => {
    let noPdfAds = ads.filter((e) => e["pdf"] === undefined);
    if (
      noPdfAds.length > 0 &&
      loading === false &&
      ads.length !== reports.length
    ) {
      generateReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ads, loading, reports]);

  return (
    <div className="reports-container">
      <div className="reports-card">
        <h3 className="title">Reportes</h3>
        <hr />
        {ads.length > 0 && (
          <div className="reports-table">
            <table className="table">
              <thead className="reports-table-header">
                <tr className="reports-table-row">
                  <th>Anunciante</th>
                  <th>Anuncio</th>
                  <th>Link</th>
                  <th>Periodo</th>
                  <th>Reporte</th>
                </tr>
              </thead>
              <tbody>
                {reports.map((ad) => (
                  <tr key={ad.id}>
                    <td>{ad.advertiser}</td>
                    <td>
                      <a href={ad.media} target="_blank" rel="noreferrer">
                        Anuncio
                      </a>
                    </td>
                    <td>
                      <a href={ad.link} target="_blank" rel="noreferrer">
                        Link
                      </a>
                    </td>
                    <td>
                      {ad.from_date} - {ad.to_date}
                    </td>
                    <td>
                      {ad.pdf ? (
                        <a href={ad.pdf} target="_blank" rel="noreferrer">
                          Descargar
                        </a>
                      ) : (
                        <div className="dot-loading-container">
                          <div className="dot-falling"></div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <hr />
          </div>
        )}
        {loading ? (
          <div className="dot-falling"></div>
        ) : (
          <div>
            {jwt == null && (
              <>
                <input
                  type="text"
                  placeholder="Usuario"
                  onChange={(e) =>
                    setForm({ ...form, identifier: e.target.value })
                  }
                />
                <input
                  type="password"
                  placeholder="Contraseña"
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                />
              </>
            )}
            <select
              placeholder="Mes"
              onChange={(e) => setForm({ ...form, month: e.target.value })}
            >
              <option value="enero">Enero</option>
              <option value="febrero">Febrero</option>
              <option value="marzo">Marzo</option>
              <option value="abril">Abril</option>
              <option value="mayo">Mayo</option>
              <option value="junio">Junio</option>
              <option value="julio">Julio</option>
              <option value="agosto">Agosto</option>
              <option value="septiembre">Septiembre</option>
              <option value="octubre">Octubre</option>
              <option value="noviembre">Noviembre</option>
              <option value="diciembre">Diciembre</option>
            </select>
            <button type="submit" onClick={jwt ? getAds : login}>
              Generar reporte
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
