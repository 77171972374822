import { useContext } from "react";
import { FeaturedArticle } from "../articles/FeaturedArticle";
import { FirstArticle } from "../articles/FirstArticle";
import { SecondArticle } from "../articles/SecondArticle";
import { ThemeMode } from "../../App";
// import { Article } from "../articles/Article";

export const HomeHeader = (props) => {
  const { articles } = props;
  const { isDarkMode, showAboveThreshold } = useContext(ThemeMode);

  const featuredArticle = articles[0] ? articles[0] : null;
  const firstArticle = articles[1] ? articles[1] : null;
  const secondArticle = articles[2] ? articles[2] : null;

  return (
    <>
      <div className={`ml-featured-container ${showAboveThreshold ? 'with-ad' : 'without-ad'}`}>
        <div className={`ml-featured-left ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
          {featuredArticle ? (
            <FeaturedArticle article={featuredArticle} />
          ) : (
            <div className="ml-empty-data-container">
              <div className="ml-empty-data">No hay publicaciones</div>
            </div>
          )}
        </div>
        <div className={`ml-featured-right ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
          {firstArticle ? (
            <FirstArticle article={firstArticle} />
          ) : (
            <div className="ml-empty-data-container">
              <div className="ml-empty-data">No hay publicaciones</div>
            </div>
          )}
          {secondArticle ? (
            <SecondArticle article={secondArticle} />
          ) : (
            <div className="ml-empty-data-container">
              <div className="ml-empty-data">No hay publicaciones</div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="ml-section">
        <div className="ml-articles-grid">
          {articles.length > 0 &&
            articles
              .slice((articles.length - 3) * -1)
              .map((article, idx) => {
                return (
                  <Article key={idx} article={article} />
                )
              }
              )}
        </div>
      </div> */}
      <br />
      <br />
    </>
  );
};
