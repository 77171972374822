import { HashLink as Link } from "react-router-hash-link";
import { config } from "../../config";
import { useContext } from "react";
import { ThemeMode } from "../../App";

export const Article = (props) => {
  const { article } = props;
  const { isDarkMode } = useContext(ThemeMode);

  const imagen = article.image
    ? article.image.url
    : "/uploads/Marcelo_Longobardi_NEUTRA_1f501e9366.jpg";

  return (
    <div>
      <div className="ml-card">
        <div
          className={`ml-card-img ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
          style={{
            backgroundImage: `url(${config.API_URL}${imagen})`,
          }}
        >
          <div className="ml-card-brand">
            {article.section != null ? article.section.singular_name : ""}
          </div>
        </div>
        <div className="ml-card-content">
          <Link smooth to={"/nota/" + article.id} className="ml-card-link">
            <span style={{ color: "#ffffff" }}>{article.title}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
