// import { Section2 } from "../home/Section2";
import "../../sass/ml_styles/_ad.scss";
import { BottomAdMobile } from "./BottomAdMobile";
import { BottomAdDesktop } from "./BottomAdDesktop";

export const BottomAd = (props) => {
  const { xBottom, handleClose, smDevice } = props;

  return smDevice ? (
    <BottomAdMobile xBottom={xBottom} handleClose={handleClose} />
  ) : (
    <BottomAdDesktop xBottom={xBottom} handleClose={handleClose} />
  );
};
