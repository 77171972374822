import { SET_ADS } from "./actionTypes";

const initialState = {
  ads: [],
  loading: true,
};

export function adsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ADS: {
      return {
        ...action.payload.ads,
      };
    }
    default:
      return state;
  }
}
