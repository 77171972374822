import { HashLink as Link } from "react-router-hash-link";
import { config } from "../../config";

export const FeaturedArticle = (props) => {
  const { article } = props;

  //console.log("FeaturedArticle", article);

  return (
    <div className="ml-featured-card">
      <div
        className="ml-featured-card-img"
        style={{
          backgroundImage: `url(${config.API_URL}${article.image.url})`,
        }}
      >
        <div className="ml-featured-card-brand brand-left">
          {article.section != null ? article.section.singular_name : ""}
        </div>
      </div>
      <div className="ml-featured-card-content">
        <Link
          smooth
          to={"/nota/" + article.id}
          className="ml-featured-card-link"
        >
          <span style={{ color: "#fff" }}>{article.title}</span>
        </Link>
      </div>
    </div>
  );
};
