import axios from "axios";
import { SET_ADS } from "./actionTypes";
import { config } from "../../config";

// SET POSTS
export const getAds = () => async (dispatch) => {
  dispatch(
    setAds({
      ads: [],
      loading: true,
    })
  );

  axios
    .get(`${config.API_URL}/api/ads`)
    .then(async (response) => {
      dispatch(
        setAds({
          ads: response.data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setAds({
          ads: [],
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};

export const setAds = (ads) => {
  return { type: SET_ADS, payload: { ads } };
};

export const getAdsByDate = (fecha) => async (dispatch) => {
  dispatch(
    setAds({
      ads: [],
      loading: true,
    })
  );

  axios
    .get(`${config.API_URL}/api/ads`)
    .then(async (response) => {
      dispatch(
        setAds({
          ads: response.data,
          loading: false,
        })
      );
    })
    .catch((error) => {
      dispatch(
        setAds({
          ads: [],
          loading: false,
        })
      );
      console.log("HA OCURRIDO UN ERROR");
      console.log(error.message);
      throw error;
    });
};
